import "moment/locale/it";
import "./tempusdominus-bootstrap-4";

function init() {
  moment.locale('it');
  $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
    allowInputToggle: true,
    buttons: {
      showToday: false,
      showClear: true,
      showClose: false
    },
    icons: {
      time: 'far fa-clock',
      date: 'far fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'far fa-calendar-check-o',
      clear: 'far fa-trash-alt',
      close: 'far fa-times'
    }
  });
  $(document).on('change.datetimepicker', '[data-provide="datetimepicker"]', function(event) {
    let $this = $(this);
    let dateVal = '';
    if ( event.date ) {
      dateVal = event.date.format ? event.date.format('YYYY-MM-DD HH:mm:ss Z') : event.date;
    }
    $($this.data('ref')).val(dateVal);
  });
  $(document).on('change.datetimepicker', '[data-provide="datepicker"]', function(event) {
    let $this = $(this);
    let dateVal = '';
    if ( event.date ) {
      dateVal = event.date.format ? event.date.format('YYYY-MM-DD') : event.date;
    }
    $($this.data('ref')).val(dateVal);
  });
}

function initOnLoad() {
  // datetimepicker
  $('[data-provide="datetimepicker"]').each(function(){
    const $el = $(this);
    $el.datetimepicker({
      locale: $(this).data('locale') || 'it',
      // format: 'L LT', TODO
      format: 'DD/MM/YYYY HH:mm',
      viewMode: $el.data('view-mode') || 'days',
      useCurrent: false
    });
  });
  // datepicker
  $('[data-provide="datepicker"]').each(function(){
    const $el = $(this);
    $(this).datetimepicker({
      locale: $el.data('locale') || 'it',
      // format: 'L', TODO
      format: 'DD/MM/YYYY',
      viewMode: $el.data('view-mode') || 'days',
      useCurrent: false
    });
  });
}

export default {
  init,
  initOnLoad,
};