import "bootstrap";
import Datetimepicker from "./datetimepicker";
import "bootstrap-fileinput";
import "bootstrap-fileinput/themes/fas/theme";
import "bootstrap-fileinput/js/locales/it";

import MyApp from "./app";
import "./entrust_request_wizard";

window.MyApp = MyApp;
Datetimepicker.init();

$(document).on('change', '[data-autosubmit=change]', function(event) {
  $(this).closest('form').submit();
});


/*$("#sidebar > .sidebar-content").mCustomScrollbar({
    theme: "minimal-dark",
    scrollInertia: 250,
    mouseWheel: {
      preventDefault: true,
    }
  });*/

// LEFT SIDEBAR
$(document).on('click', '#sidebarCollapse', function () {
  $('#sidebar, .main').toggleClass('collapsed');
  //$('.collapse.in').toggleClass('in');
  //$('a[aria-expanded=true]').attr('aria-expanded', 'false');
});

$(document).on('click', '.sidebar-collapse', function() {
  $.ajax({
    method: 'POST',
    url: '/toggle_sidebar_mode',
    data: { collapsed: $('#sidebar').hasClass('collapsed') }
  })
});

//RIGHT SIDEBAR
$(document).on('click', '#dismiss, .overlay', function () {
  // hide sidebar
  $('#rightSidebar').removeClass('active');
  // hide overlay
  $('.sidebar-overlay').removeClass('active');
});

$(document).on('click', '#rightSidebarCollapse', function () {
  // open sidebar
  $('#rightSidebar').addClass('active');
  // fade in the overlay
  $('.sidebar-overlay').addClass('active');
  //$('.collapse.in').toggleClass('in');
  //$('a[aria-expanded=true]').attr('aria-expanded', 'false');
});

// bootstrap-fileinput utils
$(document).on('fileclear', 'input.file[type=file][data-clear-ref]', function(event) {
  const clearRef = $(this).data('clear-ref');
  if ( clearRef ) {
    $(clearRef).val('');
  }
});

$(document).on('change', 'input[data-auto-show], input[data-auto-hide]', MyApp.onChangeAutoShowHide);

document.addEventListener("turbolinks:load", function() {
  Datetimepicker.initOnLoad();
  MyApp.initOnLoad();
});
document.addEventListener("ajax:complete", function() {
  Datetimepicker.initOnLoad();
  MyApp.initOnLoad();
});


