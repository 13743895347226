import iziToast from "izitoast"

const app = {
  initOnLoad: function() {
    $('[data-rel="tooltip"]').tooltip({});
    $('[data-toggle="popover"]').popover({});

    const $input = $('input.file[type=file]');
    if ($input.length) {
      $input.fileinput();
    }

    $('input[data-auto-show], input[data-auto-hide]').each(function(index, el) {
      app.onChangeAutoShowHide.call(el);
    });
  },

  onChangeAutoShowHide: function() {
    const $this = $(this);
    let showOnChecked = true;
    let rel = $this.data('auto-show');
    if ( !rel ) {
      rel = $this.data('auto-hide');
      showOnChecked = false;
    }
    const $el = $(rel);
    if ( $this.is(':checked') ) {
      showOnChecked ? $el.show() : $el.hide();
    } else {
      showOnChecked ? $el.hide() : $el.show();
      // $el.find('input').val('');
    }
  },

  flashMessage: function(name, msg, position){
    let obj = {
      message: msg,
      timeout: 8000,
      position: position
    };
    if (name === 'error'){
      obj.title = 'Errore';
      obj.color = 'red';
    } else if (name === 'notice') {
      obj.title = 'Successo';
      obj.color = 'green';
    } else if (name === 'alert') {
      obj.title = 'Attenzione';
      obj.color = 'yellow';
    }
    iziToast.show(obj);
  },

  check_exports_download: function(uri, auth_headers, entrust_request_id){
    var headers = JSON.parse(auth_headers);
    var id_interval = setInterval(function(){
      var interval_query_string = uri.indexOf('?') < 0 ? ('?id_set_interval=' + id_interval) : ('&id_set_interval=' + id_interval);
      $.ajax({
        url: uri + interval_query_string,
        type: "GET",
        contentType: "application/json",
        beforeSend: function(xhr){
          xhr.setRequestHeader('API-KEY', headers['API-KEY']);
          xhr.setRequestHeader('authorization', headers['authorization']);
        }
      }).done(function(data){
        console.log(data);
        if(data.completed){
          app.exports_ready(entrust_request_id, data.queue_id, data.type, data.entrust_code, id_interval);
          clearInterval(id_interval);
        }
      }).fail(function(data) {
        console.log(data.responseText)
        clearInterval(id_interval);
      });
    }, 10000);
  },

  exports_ready: function(entrust_request_id, queue_id, queue_type, entrust_code, interval_id){
    let obj = {
      timeout: 0,
      position: 'topRight',
      title: 'Successo',
      color: 'green'
    };
    let url_download = `/entrust_requests/${entrust_request_id}/download_completed_entrust_report/${queue_id}`;
    let url_cancel = `${url_download}?clear=true`;
    let links = "<a data-turbolinks='false' class='btn btn-primary btn-xs download' href='" + url_download + "'>" +
      "<i class='fa fa-download'></i> Scarica file" +
      "</a>&nbsp;" +
      "<a data-remote='true' class='btn btn-default btn-xs queue-" + queue_id + "' href='" + url_cancel + "'>" +
      "<i class='fa fa-times'></i> Annulla</a>";
    if(queue_type == "XmlExporterQueue"){
      obj.message = "L'esportazione XML richiesta per l'affido " + entrust_code + " è stata completata. Puoi scaricare il file cliccando il seguente link <br><br>" +
        links;
    }else if(queue_type == "PdfExporterQueue"){
      obj.message = "L'esportazione PDF richiesta per l'affido " + entrust_code + " è stata completata. Puoi scaricare il file cliccando il seguente link <br><br>" +
        links;
    }
    iziToast.show(obj);
    clearInterval(interval_id);
    $(".iziToast-capsule a.download").click(function(){
      let $el = $(this).closest('.iziToast-capsule');
      setTimeout(function(){
        $el.remove()
      }, 3000)
    });
  },

  drawChartByCampaignKind: function(data, id_block) {
    data.unshift(["Tipologia", "Occorrenze", "ID Tipologia"]);
    var gdata = google.visualization.arrayToDataTable(data);
    var options = {
      is3D: false,
      pieHole: 0.4,
      width: $('#' + id_block).width(),
      height: $('#' + id_block).width() / 2,
      slices: {
        0: { color: '#003f5c' },
        1: { color: '#2f4b7c' },
        2: { color: '#665191' },
        3: { color: '#a05195' },
        4: { color: '#d45087' },
        5: { color: '#f95d6a' },
        6: { color: '#ff7c43' },
        7: { color: '#ffa600' },
        8: { color: '#365c28' },
        9: { color: '#496a24' },
        10: { color: '#60771d' },
        11: { color: '#798313' },
        12: { color: '#968e04' },
        13: { color: '#ffa600' },
        14: { color: '#8636a0' },
        15: { color: '#a23fb6' },
        16: { color: '#c048cc' },
        17: { color: '#df51e2' },
        18: { color: '#7e4055' },
        19: { color: '#9c5352' },
        20: { color: '#b06c4d' }
      }
    };

    var chart = new google.visualization.PieChart(document.getElementById(id_block));

    chart.draw(gdata, options);

    var campaign_kind_id = undefined;
    google.visualization.events.addListener(chart, 'select', function() {
      if(chart.getSelection().length > 0){
        campaign_kind_id = gdata.getValue(chart.getSelection()[0].row, 2);
      }
      $.get("campaign_kind_requests/" + campaign_kind_id)
    });
    google.visualization.events.addListener(chart, 'onmouseover', function(){$('#' + id_block).css('cursor','pointer')});
    google.visualization.events.addListener(chart, 'onmouseout', function(){$('#' + id_block).css('cursor','default')});
  },

  drawChartByAuthor: function(data, id_block) {
    data.unshift(["Utente", "Occorrenze", "ID Utente"]);
    var gdata = google.visualization.arrayToDataTable(data);
    var options = {
      is3D: false,
      pieHole: 0.4,
      width: $('#' + id_block).width(),
      height: $('#' + id_block).width() / 2,
      slices: {
        0: { color: '#003f5c' },
        1: { color: '#2f4b7c' },
        2: { color: '#665191' },
        3: { color: '#a05195' },
        4: { color: '#d45087' },
        5: { color: '#f95d6a' },
        6: { color: '#ff7c43' },
        7: { color: '#ffa600' },
        8: { color: '#365c28' },
        9: { color: '#496a24' },
        10: { color: '#60771d' },
        11: { color: '#798313' },
        12: { color: '#968e04' },
        13: { color: '#ffa600' },
        14: { color: '#8636a0' },
        15: { color: '#a23fb6' },
        16: { color: '#c048cc' },
        17: { color: '#df51e2' },
        18: { color: '#7e4055' },
        19: { color: '#9c5352' },
        20: { color: '#b06c4d' }
      }
    };

    var chart = new google.visualization.PieChart(document.getElementById(id_block));

    chart.draw(gdata, options);

    var author_id = undefined;
    google.visualization.events.addListener(chart, 'select', function() {
      if(chart.getSelection().length > 0){
        author_id = gdata.getValue(chart.getSelection()[0].row, 2);
      }
      window.location.assign("/entrust_requests?q[author_id_eq]=" + author_id)
    });
    google.visualization.events.addListener(chart, 'onmouseover', function(){$('#' + id_block).css('cursor','pointer')});
    google.visualization.events.addListener(chart, 'onmouseout', function(){$('#' + id_block).css('cursor','default')});
  }
};

export default app;
