$(document).on('change', '#new_entrust_request_wizard input[name="entrust_request[source_type]"]', function() {
  const source_type = $(this).val();
  // console.log(source_type);
  $('.source-type').addClass('d-none');
  $(`.source-type-${source_type}`).removeClass('d-none');
  if ( source_type === 'file' ) {
    $('#entrust_request_single_cf').val('');
  } else if ( source_type === 'single_cf' ) {
    $("#entrust_request_attachment").fileinput('clear');
    $('#entrust_request_attachment_cache').val('');
  }
});

// Back step su entrust_request create
$(document).on('click', '.js-create-request-back', function(e) {
  $("#step").val($(this).data('step'));
  $(this).closest('form').submit();
});
